body {   
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}

.headTitle{
    font-weight: 500;
    color: antiquewhite;
    letter-spacing: 1;
    text-shadow: 0 0 3px;
}

.card-container{
    display: flex;   
    flex-direction: row;   
    align-items: center;   
   justify-content: center;
}

.cards{  
    display: inline-block;  
    width: 130px;
    height: 110px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 6px;
    border-radius: 8px;   
    padding: 6px;
    border: 2px solid rgb(240, 240, 240);  
    
}

.cards img{    
    height: 50px;    
}

.cards strong{
    font-size: 12px;
    color: antiquewhite;
    text-shadow: 0 0 3px #232323;
}

.cards h5{    
    color: antiquewhite;
    text-shadow: 0 0 3px #232323;
}

.regBtn{
    color: #4A235A;
    font-size: small;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 8px 12px;
    border: 2px solid #ffffff;
    background-color: aliceblue;
    border-radius: 4px;
    margin: 6px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.regBtn:hover{
    color: aliceblue;    
   background-color: rgba(0,0,0, 0.3);
}

.loginBtn{
    color: #4A235A;
    font-size: small;
    letter-spacing: 1px;
    font-weight: 600;
    background-color: aliceblue;
    padding: 8px 12px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin: 6px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.loginBtn:hover{
    color: aliceblue;    
   background-color: rgba(0,0,0, 0.3);
}

.footer {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* background: rgb(232,219,252, 0.5);
    background: linear-gradient(90deg, rgba(232,219,252,1) 0%, rgba(248,249,210,1) 100%); */
    color: rgb(13, 13, 13);   
    padding: 6px 12px; 
    border-top: 2px solid #ABB2B9;
    
  }

  .fc1{
    font-size: small;
    font-weight: 600;
  }

  .fc1 a{
    text-decoration: none;
    color: #1C2833;
  }

  .fc2{
    text-align: right;
  }

  .fc2 img{
    width: 100px;
  }

  .modalHead{
    background-color: #F4F6F6;
  }

  
@media screen and (max-width: 700px) {
    .cards{
        height: 140px;
    }
  }